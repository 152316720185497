import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  add(event) {
    this.frame = this.element.closest("turbo-frame");
    const url = new URL(this.frame.src, window.location.origin);

    Object.entries(event.params.params).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });

    this.frame.src = url.toString()
  }

}
